<template>
  <div class="d-flex flex-column flex-lg-row">
    <div class="flex-column flex-lg-row-auto w-100 w-lg-300px w-xl-400px mb-10 mb-lg-0">
      <div class="card">
        <div class="card-header border-0 pt-6">
          <div class="card-title">
            <h4>Search</h4>
          </div>
        </div>
        <div class="card-body pt-0">
          <div class="row mb-7">
            <div class="col">
              <Field
                type="text"
                class="form-control form-control-lg form-control-solid"
                name="name"
                placeholder="Name"
                @change="(event) => setSearchProperty(event, 'full_name')"
              />
              <ErrorMessage
                class="fv-plugins-message-container invalid-feedback"
                name="name"
              />
            </div>
          </div>
          <div class="row mb-7">
            <div class="col">
              <Field
                type="text"
                class="form-control form-control-lg form-control-solid"
                name="title"
                placeholder="Job Title"
                @change="(event) => setSearchProperty(event, 'title')"
              />
              <ErrorMessage
                class="fv-plugins-message-container invalid-feedback"
                name="title"
              />
            </div>
          </div>
          <div class="row mb-7">
            <div class="col">
              <Field
                type="text"
                class="form-control form-control-lg form-control-solid"
                name="company_name"
                placeholder="Company Name"
                @change="(event) => setSearchProperty(event, 'company_name')"
              />
              <ErrorMessage
                class="fv-plugins-message-container invalid-feedback"
                name="company_name"
              />
            </div>
          </div>
          <div class="row mb-7">
            <div class="col">
              <Field
                type="text"
                class="form-control form-control-lg form-control-solid"
                name="state"
                placeholder="State"
                @change="(event) => setSearchProperty(event, 'state')"
              />
              <ErrorMessage
                class="fv-plugins-message-container invalid-feedback"
                name="state"
              />
            </div>
          </div>

          <div class="row mb-7">
            <div class="col">
              <Field
                type="text"
                class="form-control form-control-lg form-control-solid"
                name="country"
                placeholder="Country"
                @change="(event) => setSearchProperty(event, 'country')"
              />
              <ErrorMessage
                class="fv-plugins-message-container invalid-feedback"
                name="country"
              />
            </div>
          </div>

          <div class="row mb-7">
            <div class="col">
              <button
                type="button"
                class="btn btn-primary me-10"
                id="kt_button_1"
                :data-kt-indicator="isLoading"
              >
                <span class="indicator-label" @click="search">Search</span>
                <span class="indicator-progress">
              Please wait...
              <span
                class="spinner-border spinner-border-sm align-middle ms-2"
              ></span>
            </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-lg-row-fluid ms-lg-7 ms-xl-10">
      <div class="card" v-if="people.length">
        <div class="card-header border-0 pt-6">
          <!--begin::Card title-->
          <div class="card-title">
            <h4>Results ({{ total }})</h4>
          </div>
          <!--begin::Card title-->
          <!--begin::Card toolbar-->
          <div class="card-toolbar">
            <!--begin::Toolbar-->
            <div
              v-if="selectedPeople.length > 0"
              class="d-flex justify-content-end"
              data-kt-customer-table-toolbar="base"
            >
              <!--begin::Export-->
              <button
                type="button"
                class="btn btn-light-primary me-3"
                data-bs-toggle="modal"
                data-bs-target="#kt_customers_export_modal"
              >
            <span class="svg-icon svg-icon-2">
              <inline-svg src="media/icons/duotune/arrows/arr078.svg" />
            </span>
                Export
              </button>
              <!--end::Export-->
              <!--begin::Add to List-->
              <button
                type="button"
                class="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_add_to_list"
              >
            <span class="svg-icon svg-icon-2">
              <inline-svg src="media/icons/duotone/Navigation/Plus.svg" />
            </span>
                Add to List
              </button>
              <!--end::Add to List-->
            </div>
            <!--end::Toolbar-->
          </div>
          <!--end::Card toolbar-->
        </div>
        <div class="card-body pt-0">
          <Datatable
            :table-data="people"
            :table-header="tableHeader"
            :total="total"
            :enable-items-per-page-dropdown="true"
            :pageChange="pageChange"
            :rows-per-page-change="rowsPerPageChange"
          >
            <template v-slot:cell-checkbox="{ row: customer }">
              <div
                class="form-check form-check-sm form-check-custom form-check-solid"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  :value="customer.id"
                  @change="event => checkRows(event, customer.id)"
                />
              </div>
            </template>
            <template v-slot:cell-email="{ row: customer }">
              <a href="#" class="text-gray-600 text-hover-primary mb-1">
                {{ customer.email === "null" ? "-" : customer.email }}
              </a>
            </template>
            <template v-slot:cell-company_name="{ row: customer }">
              {{ customer.company_name === "null" ? "-" : customer.company_name }}
            </template>
            <template v-slot:cell-Title="{ row: customer }">
              {{ customer.Title === "null" ? "-" : customer.Title }}
            </template>
            <template v-slot:cell-phone_number="{ row: customer }">
              {{ customer.phone_number === "null" ? "-" : customer.phone_number }}
            </template>
            <template v-slot:cell-state="{ row: customer }">
              {{ customer.state === "null" ? "-" : customer.state }}
            </template>
            <template v-slot:cell-country="{ row: customer }">
              {{ customer.country === "null" ? "-" : customer.country }}
            </template>
            <template v-slot:cell-company_phone="{ row: customer }">
              {{ customer.company_phone === "null" ? "-" : customer.company_phone }}
            </template>
            <template v-slot:cell-paymentMethod="{ row: customer }">
              <img :src="customer.payment.icon" class="w-35px me-3" alt="" />{{
                customer.payment.ccnumber
              }}
            </template>
            <template v-slot:cell-date="{ row: customer }">
              {{ customer.date }}
            </template>
            <template>
              <a
                href="#"
                class="btn btn-sm btn-light btn-active-light-primary"
                data-kt-menu-trigger="click"
                data-kt-menu-placement="bottom-end"
                data-kt-menu-flip="top-end"
              >Actions
                <span class="svg-icon svg-icon-5 m-0">
              <inline-svg src="media/icons/duotune/arrows/arr072.svg" />
            </span>
              </a>
              <!--begin::Menu-->
              <div
                class="
              menu
              menu-sub
              menu-sub-dropdown
              menu-column
              menu-rounded
              menu-gray-600
              menu-state-bg-light-primary
              fw-bold
              fs-7
              w-125px
              py-4
            "
                data-kt-menu="true"
              >
                <!--begin::Menu item-->
                <div class="menu-item px-3">
                  <router-link
                    to="/apps/customers/customer-details"
                    class="menu-link px-3"
                  >View
                  </router-link>
                </div>
                <!--end::Menu item-->
                <!--begin::Menu item-->
                <div class="menu-item px-3">
                  <a class="menu-link px-3">
                    Delete
                  </a>
                </div>
                <!--end::Menu item-->
              </div>
              <!--end::Menu-->
            </template>
          </Datatable>
        </div>
      </div>
      <div class="card" v-else>
        <div class="card-header border-0 pt-6">
          <!--begin::Card title-->
          <div class="card-title">
            <h4>No Results</h4>
          </div>
          <!--begin::Card title-->
        </div>
        <div class="card-body pt-0">
        </div>
      </div>
    </div>
  </div>
  <AddToList list-type="People"></AddToList>
</template>

<script>
import store from "@/store";
import { Field, ErrorMessage } from "vee-validate";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import AddToList from "@/views/search/business/AddToList";

export default {
  name: "People",
  computed: {
    people() {
      return store.getters["People/getRecords"];
    },
    getSearchValues() {
      return store.getters["People/getSearchValues"];
    },
    total() {
      return store.getters["People/getTotal"];
    },
    selectedPeople() {
      return store.getters["People/getSelectedPeople"];
    }
  },
  components: {
    Field,
    ErrorMessage,
    Datatable,
    AddToList
  },
  data() {
    return {
      pagination: {
        page: 1,
        rowsPerPage: 15
      },
      isLoading: "off",
      checkedCustomers: [],
      tableHeader: [{
        key: "checkbox"
      },
        {
          name: "Name",
          key: "full_name",
          sortable: true
        },
        {
          name: "Email",
          key: "email",
          sortable: true
        },
        {
          name: "Title",
          key: "Title",
          sortable: true
        },
        {
          name: "Company",
          key: "company_name"
        },
        {
          name: "Phone",
          key: "phone_number",
          sortable: true
        },
        {
          name: "Company Phone",
          key: "company_phone",
          sortable: true
        },
        {
          name: "State",
          key: "state",
          sortable: true
        },
        {
          name: "Country",
          key: "country",
          sortable: true
        },
        ]
    };
  },
  watch: {
    pagination: {
      handler() {
        this.search();
      },
      deep: true
    }
  },
  mounted() {
    store.dispatch("setBreadcrumbAction", { title: "People" });
  },
  methods: {
    search() {
      this.checkedCustomers = [];
      this.isLoading = "on";
      const search = { ...this.getSearchValues, ...this.pagination };
      const queryString = Object.keys(search).map(key => key + "=" + search[key]).join("&");
      store.dispatch("People/search", queryString).then(() => this.isLoading = "off");
    },
    serialize(obj) {
      const str = [];
      for (const p in obj) {
        str.push(`${ encodeURIComponent(p) }=${ encodeURIComponent(obj[p]) }`);
      }
      return str.join("&");
    },
    setSearchProperty(event, field) {
      store.dispatch("People/setSearchProperty", { field, value: event.target.value });
    },
    pageChange(page) {
      this.pagination.page = page;
    },
    rowsPerPageChange(rows) {
      this.pagination.rowsPerPage = rows;
    },
    checkRows(event, id) {
      store.dispatch("People/selectPeople", { id, checked: event.target.checked });
    }
  }
};
</script>

<style scoped>

</style>